import React from 'react';

const AccountDeletionPage = () => {
  return (
    <div style={{ padding: '15px' }}>
      <h1>Account Deletion for Laffbag</h1>
      <p>
        This page provides the information on how to delete your account and the associated data from Laffbag.
      </p>

      <h2>How to Delete Your Account</h2>
      <p>Please follow the steps below:</p>

      <ol>
        <li>Open Laffbag and navigate to your profile located in the bottom right-hand corner of the main landing page.</li>
        <li>Tap on the settings icon, which is located in the top right corner of the screen.</li>
        <li>On the settings page, find and tap on 'Delete Account'.</li>
      </ol>

      <p>
        Please note, by deleting your account, you will be removing all of your account data including your profile, photos, and videos. There is no additional retention period beyond account deletion - once your account is deleted, all of your data is permanently removed from our servers.
      </p>

      <h2>Data Handling After Account Deletion</h2>
      <p>When you delete your account in Laffbag, the following data is permanently deleted and cannot be recovered:</p>

      <ul>
        <li>Your profile information</li>
        <li>All your photos and videos</li>
      </ul>

      <p>
        There will be no data kept on our servers post the deletion of your account. Please note, deleting your account is irreversible. Once your account is deleted, it cannot be recovered.
      </p>
    </div>
  );
};

export default AccountDeletionPage;
