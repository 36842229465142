import React from 'react';

const Policy = () => (
  <div style={{textAlign: "center", marginLeft: "10px", marginRight: "10px"}}>
    <h2>Privacy Policy</h2>
    We and our technology partners ask for your consent to the use of cookies to store and access personal data on your device; for example: unique identifiers and your browsing patterns. 
    This may be used for advert and content personalisation, measurement, audience insights and product development. Some of our partners process personal data on the basis of legitimate interest.
    Consent is optional, however may restrict content and advert personalisation.
  </div>
);

export default Policy;
