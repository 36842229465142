import React from 'react';

const iOS = () => (
  <div>
    <h2>iOS</h2>
    // Add your terms content here
  </div>
);

export default iOS;
