import React from 'react';

const Instagram = () => (
  <div>
    <h2>Instagram</h2>
    // Add your terms content here
  </div>
);

export default Instagram;
