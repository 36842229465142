import '../App.css';
import { useParams } from "react-router-dom";
import { initializeApp } from 'firebase/app';
import { getAnalytics } from "firebase/analytics";
import { getFirestore, collection, getDocs, getDoc, doc } from 'firebase/firestore/lite';
import { useEffect, useState, useRef } from 'react';
import ReactPlayer from 'react-player'
const firebaseConfig = {
  apiKey: "AIzaSyC08qIalF4EFG_L3_vjG8XmlAh8Gj8C7UE",
  authDomain: "laffbag-5c814.firebaseapp.com",
  projectId: "laffbag-5c814",
  storageBucket: "laffbag-5c814.appspot.com",
  messagingSenderId: "426398261879",
  appId: "1:426398261879:web:23013eafec5d31e8719c72",
  measurementId: "G-V50CH465D8"
};

function ViewContent() {
  const [lbContent, setLbContent] = useState(null)
  const [visible, setVisible] = useState(true)
  const [disappearAnim, setDisappearAnim] = useState(true)
  const [playing, setPlaying] = useState(false)

  const player = useRef(null)

  let params = useParams();

  const app = initializeApp(firebaseConfig);
  const analytics = getAnalytics(app);
  const db = getFirestore(app);


  useEffect(() => {
    const getContent = async () => {
      const ref = doc(db, 'all/' + params.contentId)
      const contentSnapshot = await getDoc(ref);
      setLbContent(contentSnapshot.data())
      return contentSnapshot;
    }

    getContent();
  }, [db, params.contentId])

  useEffect(() => {
    if (disappearAnim) {
      setTimeout(() => {
        setVisible(false)
        setDisappearAnim(false)
      }, 2900)
    }

  }, [disappearAnim])

  useEffect(() => {
    setDisappearAnim(true)
  }, [])

  return (
    <div className="App">
      {
        lbContent &&
        <div class="ContentMain">

          <>
            {lbContent.type.includes("image") && <div class="ContentDiv">
              <img width={'100%'} height={'100%'} class="Content" src={lbContent.uri} />
            </div>}
            {lbContent.type === "video" && <div class="ContentDiv">
              <ReactPlayer 
                ref={player} playing={playing} loop={true} width='100%'
                height='100%' class="Content" url={lbContent.uri} />
            </div>}
            {visible && <div style={{ position: 'absolute', bottom: 0, width: '100%', height: '15%', backgroundColor: 'rgba(0, 0, 0, 0.8)', animation: disappearAnim ? "disappear 3s ease" : "appear 3s ease" }}>
              <div style={{ color: 'white', fontSize: 25 }}>{lbContent.title}</div>
              <div style={{ color: 'white', fontSize: 25 }}>{lbContent.description}</div>

            </div>}
            <div onClick={() => {
              if (!visible) {
                setVisible(true)
              }
              else {
                setDisappearAnim(true)
              }
            }} style={{ position: 'absolute', bottom: 0, width: '100%', height: '15%' }}>

            </div>

            {lbContent.type === "video" && <div onClick={() => {

              if (playing) {
                setPlaying(false)
              }
              else {
                setPlaying(true)
              }

            }} style={{ position: 'absolute', top: 0, width: '100%', height: '85%' }}>
              <div style={{ display: 'flex', width: '100%', height: '100%', justifyContent: 'center', alignItems: 'center', textAlign: 'center' }}>
                {!playing && <img width={75} height={75} style={{ alignSelf: 'center', width: 75, height: 75 }} src={require('../png/PLAY.png')} />}

              </div>
            </div>}

            <div style={{ position: 'absolute', top: 10, right: 20 }}>
              <div style={{  }}>
                <img width={75} height={150} style={{ alignSelf: 'center' }} src={require('../png/LAFFBAG_LOGO.png')} />

              </div>
            </div>
          </>

        </div>
      }
    </div>
  );
}

export default ViewContent;
