import React from 'react';

const LinkedIn = () => (
  <div>
    <h2>LinkedIn</h2>
    // Add your terms content here
  </div>
);

export default LinkedIn;
