import React from 'react';

const Contact = () => (
  <div>
    <h2>Contact</h2>
    // Add your terms content here
  </div>
);

export default Contact;
