import React from 'react';

const Android = () => (
  <div>
    <h2>Android</h2>
    // Add your terms content here
  </div>
);

export default Android;
