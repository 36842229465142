import React from 'react';

const FAQ = () => (
  <div>
    <h2>FAQ</h2>
    // Add your terms content here
  </div>
);

export default FAQ;
