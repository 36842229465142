import React from 'react';

const Download = () => (
  <div>
    <h2>Download</h2>
    // Add your terms content here
  </div>
);

export default Download;
